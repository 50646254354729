import React from 'react';
import { navigateTo } from 'gatsby-link';

import Layout from '../components/Layout';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section section--gradient">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="section">
                  <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                    Форма за контакти и запитвания
                  </h1>
                  <p>
                    Първата консултация е безплатна и е в рамките на 30 минути!
                  </p>
                  <p>
                    Чрез нея ще можем да изготвим персонализирана практика
                    според нуждите.
                  </p>
                  <br />
                  <br />
                  <form
                    class="box"
                    method="post"
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    name="contact"
                    action="/thankyou"
                    onSubmit={this.handleSubmit}
                  >
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <div class="field">
                      <label class="label">Име</label>
                      <div class="control">
                        <input
                          class="input"
                          type="text"
                          id="name"
                          name="name"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Имейл</label>
                      <div class="control">
                        <input
                          class="input"
                          type="email"
                          id="email"
                          name="email"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">
                        Няколко думи относно запитването
                      </label>
                      <div class="control">
                        <textarea
                          class="textarea"
                          id="message"
                          name="message"
                          onChange={this.handleChange}
                        ></textarea>
                      </div>
                    </div>
                    <div class="field">
                      <div class="control">
                        <button class="button is-link">Изпрати</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
